import { ADMIN_API_PATH } from "../constants";
import axios from "../helpers/axios";

/**
 * Get sensor logs
 * @param {*} params
 * @return Promise
 */
const getSensorLogsDashboard = async (params) => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SENSOR_LOGS}/dashboards`,
      { params }
    );
    return response?.data || response || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

/**
 * Get congestion situation logs
 * @param {*} params
 * @return Promise
 */
 const getCongestionSituationDashboard = async (params) => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SENSOR_LOGS}/circle-chart`,
      { params }
    );
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};

/**
 * Get sensor logs public
 * @param {*} params
 * @return Promise
 */
const getSensorLogsPublic = async (params) => {
  try {
    const response = await axios.get(
      `${ADMIN_API_PATH.ADMIN_SENSOR_LOGS_PUBLIC}/charts`,
      { params }
    );
    return response?.data || response || [];
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getRoomsInfo = async (params) => {
  return await axios.get(
    `${ADMIN_API_PATH.ADMIN_SENSOR_LOGS}/rooms-info`,
    { params }
  );
};

export const sensorLogsService = {
  getSensorLogsDashboard,
  getSensorLogsPublic,
  getCongestionSituationDashboard,
  getRoomsInfo
};
