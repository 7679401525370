<script>
import { reactive } from 'vue'
import Chart from "../chart-public";
import { useRoute } from 'vue-router';


export default {
    components: {
        Chart
    },
    setup() {
        const state = reactive({
            date: null,
        })

        const route = useRoute()

        const facilityId = route.params.id
        const { startDate, endDate } = route.query

        const onChangeDate = (date) => state.date = date

        return {
            onChangeDate,
            state,
            facilityId,
            startDate,
            endDate
            //getScreenShot,
        }
    },
}
</script>
<template>
    <div class="container">
        <div class="chart-container">
            <Chart @onChangeDate="onChangeDate" :dateDefault="state.date" :startDate="startDate" :endDate="endDate"
                :facilityId="facilityId" />
        </div>
    </div>
</template>
<style scoped>
.container {
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.chart-container {
    min-width: 900px;
}
</style>